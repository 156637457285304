import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const ContactContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allContactJson {
          edges {
            node {
              id
              pageId
              pageTitle
              pageBanner {
                textContent {
                  heading
                  content
                }
                backgroundImage
              }
              contactDetails {
                title
                contactEmail
                contactPhone
              }
            }
          }
        }
        allClientsJson {
          edges {
            node {
              id
              pageTitle
              textContent {
                heading
                content
              }
              clients {
                logo
                clientName
                clientUrl
                testimonial
                authorName
                authorDesignation
              }
            }
          }
        }
      }
    `}
    render={({
      allContactJson: { edges: contactData },
      allClientsJson: { edges: clientsData },
    }) =>
      children(
        contactData.map((e) => ({
          ...e.node,
        })),
        clientsData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

ContactContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ContactContainer;
