import React from "react";
import { Router } from "@reach/router";
import ContactPage from "../components/ContactPage";
import NotFoundPage from "./404";

const contactPageRouter = (props) => (
  <Router>
    <ContactPage {...props} path="/contact" />
    <NotFoundPage default />
  </Router>
);

export default contactPageRouter;
