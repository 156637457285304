import React from "react";
import Box from "../../base/Box";
import ContactForm from "../ContactForm/ContactForm";
import ContactContainer from "../../containers/ContactContainer";
import Section from "../Section";
import PageBanner from "../../sections/PageBanner";
import Testimonials from "../../sections/Testimonials";
import MarkdownToHtml from "../MarkdownToHtml";

const ContactPage = () => (
  <ContactContainer>
    {(contactData, clientsData) => {
      const pageId = "contact_us";
      const contactInfo = contactData.find((data) => data.id === pageId);
      const { pageBanner, contactDetails } = contactInfo;

      // clientsContent
      const clientsContent = clientsData.find((data) => data.id === "clients");
      const { textContent: clientContent, clients } = clientsContent;
      const isOurClients = clientContent || (clients && clients.length > 0);

      return (
        <>
          {pageBanner && !pageBanner.hideSection && (
            <PageBanner
              bannerContent={pageBanner.textContent}
              bgImage={pageBanner.backgroundImage}
            />
          )}
          <Section
            containerSx={{
              maxWidth: [
                null,
                "maxWidths.sm",
                "maxWidths.md",
                "maxWidths.lg",
                null,
              ],
              textAlign: "center",
            }}
            sx={{ mb: 4 }}
          >
            <MarkdownToHtml
              heading={contactDetails.title}
              headingSx={{
                h2: { color: "primary", fontWeight: "medium" },
                p: { mb: 1 },
              }}
              sx={{
                fontWeight: "medium",
                mb: 4,
                textAlign: "left",
              }}
            />
            {/* <Heading
              as="h3"
              sx={{
                color: "primary",
                fontWeight: "medium",
                mb: 5,
                textAlign: "left",
              }}
            >
              {contactDetails.contactEmail} | {contactDetails.contactPhone}
            </Heading> */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mt: -4,
                mx: -4,
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 4,
                  width: ["100%", null, null, "100%"],
                }}
              >
                <ContactForm InqueryForm />
              </Box>
            </Box>
          </Section>
          {isOurClients && (
            <Testimonials
              data={{
                clients,
                textContent: clientContent,
              }}
            />
          )}
        </>
      );
    }}
  </ContactContainer>
);

export default ContactPage;
